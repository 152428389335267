import {
  AspectRatio,
  Box,
  Flex,
  Heading,
  Show,
  Tag,
  Text,
  useBreakpointValue
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React, { useState } from 'react';
import Image from '@components/atoms/Image';
import Button from '@components/atoms/Button';
import Quote from '@components/atoms/Quote';
import {
  ArrowRightIcon,
  ExternalLinkIcon,
  PlayIcon,
  IceCream1Icon
} from '@components/atoms/icons';
import dataLayerPush from '@helpers/dataLayerPush';

const resolveSpacing = (key) => {
  const cardHeadingSpacing = {
    initial: {
      title: {
        mt: [`2`, `8`],
        mb: [`4`]
      },
      tagline: {
        mt: [`4`, `6`],
        mb: [`2`, `7`]
      }
    },
    'elements.product-card': {
      title: {
        mt: [`7`],
        mb: [`4`]
      },
      tagline: {
        mt: [`4`, `6`],
        mb: [`2`, `7`]
      }
    },
    'elements.team-card': {
      title: {
        mt: [`0`],
        mb: [`4`]
      },
      tagline: {
        mt: [`6`, `8`, `8`],
        mb: [`4`, `5`, `6`]
      }
    }
  };

  const result = cardHeadingSpacing[key];

  if (typeof result !== `undefined`) {
    return result;
  }

  return cardHeadingSpacing.initial;
};

/**
 * Card Component
 * @param {Object} cardProps Props for the card
 * @param {image} cardProps.frontFace Front side of the card
 * @param {image | video | quote} [cardProps.backFace] Back side of the card
 * @param {String} cardProps.tagline Heading below the card
 * @param {String} [cardProps.title] Extra title with card
 * @param {String} [cardProps.text] Text
 * @param {Object} [cardProps.cta] Cta
 * @param {start | end} alignButton Align CTA button
 * @param {String} aspectRatio3By2 Set 3 /2 aspect ratio of card
 * @param {String} maxWidth Set max width of card
 * @returns {JSX.Element} Card fragment
 */
const Card = ({
  data: {
    strapiComponent,
    frontFace,
    backFace,
    videoLink,
    quote,
    tags,
    date,
    tagline,
    title,
    text,
    cta,
    color
  },
  hideCTA = false,
  alignButton = `start`,
  aspectRatio3By2 = false,
  maxWidth = `31.25rem`,
  ...props
}) => {
  const [cardSide, flipCardSide] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const flipCard = (side, mobile) => {
    if (isMobile === mobile && (backFace || videoLink || quote)) {
      flipCardSide(side);
    }
  };

  let Icon;
  switch (cta?.type) {
    case `internal`:
      Icon = ArrowRightIcon;
      break;
    case `external`:
      Icon = ExternalLinkIcon;
      break;
    case `video`:
      Icon = PlayIcon;
      break;
    default:
      Icon = null;
      break;
  }

  let dataLayerType;
  switch (strapiComponent) {
    case `elements.product-card`:
      dataLayerType = `product_card`;
      break;
    case `elements.news-card`:
      dataLayerType = `news_card`;
      break;
    case `elements.brand-card`:
      dataLayerType = `brand_card`;
      break;
    case `elements.team-card`:
      dataLayerType = `team_card`;
      break;
    default:
      dataLayerType = `card`;
      break;
  }

  const colorScheme =
    strapiComponent === `elements.product-card`
      ? `secondary.pink`
      : `primary.dark-blue`;

  const alignEnd = alignButton === `end`;

  const extraDataLayerProps = (cta && cta.extraDataLayerProps) || {};

  return (
    <Box pos="relative" className="button-hover" {...props}>
      <Box
        as={cta ? GatsbyLink : Box}
        to={cta?.link || ``}
        h="100%"
        w="100%"
        pos="absolute"
        left="0"
        top="0"
        display="inline"
      />
      <Box
        display={alignEnd ? `flex` : `block`}
        flexDir="column"
        maxW={maxWidth}
        color={color || colorScheme}
        className="card-content">
        <Box pos="relative">
          <Box
            as={cta ? GatsbyLink : Box}
            to={cta?.link || ``}
            display="inline">
            <AspectRatio
              maxW={maxWidth}
              ratio={
                // eslint-disable-next-line no-nested-ternary
                strapiComponent === `elements.news-card`
                  ? 4 / 3
                  : aspectRatio3By2
                  ? 3 / 2
                  : 3 / 4
              }
              overflow="hidden"
              borderRadius="xs"
              borderBottomRightRadius={[`md`, `lg`]}
              onMouseOver={() => flipCard(true, false)}
              onClick={() => {
                return (
                  strapiComponent === `elements.team-card` &&
                  flipCard(true, true)
                );
              }}
              display={cardSide ? `none` : `block`}>
              <Image image={frontFace} />
            </AspectRatio>

            {(backFace || videoLink || quote) && (
              <AspectRatio
                maxW={maxWidth}
                ratio={aspectRatio3By2 ? 3 / 2 : 3 / 4}
                overflow="hidden"
                borderRadius="xs"
                borderBottomRightRadius={[`md`, `lg`]}
                onMouseOut={() => flipCard(false, false)}
                onClick={() => {
                  return (
                    strapiComponent === `elements.team-card` &&
                    flipCard(false, true)
                  );
                }}
                display={!cardSide ? `none` : `block`}>
                <>
                  {videoLink ? (
                    <Box
                      as="video"
                      src={videoLink}
                      controls={false}
                      autoPlay
                      loop
                      muted
                      playsInline
                      objectFit="cover"
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <Image image={backFace} />
                  )}
                  {quote && <Quote quote={quote} />}
                </>
              </AspectRatio>
            )}
          </Box>
          {strapiComponent === `elements.team-card` && !cardSide && (
            <Show below="md">
              <Box
                borderRadius="50%"
                p="4"
                backgroundColor="#fff"
                pos="absolute"
                bottom="4"
                left="4"
                pointerEvents="none">
                <IceCream1Icon color="primary.froneri-blue" w="10" h="10" />
              </Box>
            </Show>
          )}
          {tags && (
            <Flex pos="absolute" top="0" p="6" gap="4" flexWrap="wrap">
              {tags.map((tag) => (
                <Tag
                  px="4"
                  py="3"
                  key={tag.title}
                  as={GatsbyLink}
                  to={tag?.link || ``}>
                  {tag.title}
                </Tag>
              ))}
            </Flex>
          )}
        </Box>
        <Box
          px="2"
          display={alignEnd ? `flex` : `block`}
          flexDir="column"
          mt={alignEnd ? `auto` : `0`}
          flexGrow="1"
          className="card-cta-wrapper">
          {date && (
            <Heading
              as="p"
              size="xs"
              variant="detail"
              color="secondary.mid-grey"
              mt="10"
              mb="0">
              {date.slice(2).split(`-`).reverse().join(`•`)}
            </Heading>
          )}
          {tagline && (
            <Heading
              as="p"
              size="xs"
              variant="detail"
              color="secondary.mid-grey"
              mt={resolveSpacing(strapiComponent).tagline.mt}
              mb={resolveSpacing(strapiComponent).tagline.mb}>
              {tagline}
            </Heading>
          )}
          {title && (
            <Heading
              mt={resolveSpacing(strapiComponent).title.mt}
              mb={resolveSpacing(strapiComponent).title.mb}
              color={color || colorScheme}
              textDecoration="underline"
              textDecorationColor="transparent"
              textUnderlineOffset="3px"
              textDecorationThickness="2px"
              size={[`lg`, `xl`, `2xl`]}
              sx={{
                '.button-hover:hover &': {
                  color:
                    strapiComponent !== `elements.team-card` &&
                    `secondary.pink`,
                  textDecorationColor:
                    strapiComponent !== `elements.team-card` && `secondary.pink`
                }
              }}>
              {title}
            </Heading>
          )}
          {text && (
            <Text mb="4" fontSize={[`sm`, `md`, undefined]}>
              {text}
            </Text>
          )}
          {cta && !hideCTA && (
            <Button
              mt={alignEnd ? `auto` : [`2`, `4`, `8`]}
              alignSelf="start"
              as={GatsbyLink}
              to={cta.link || ``}
              variant="secondary"
              color={color || colorScheme}
              iconBorderColor={
                strapiComponent === `elements.product-card`
                  ? `secondary.pink`
                  : color || `primary.froneri-blue`
              }
              className="card-cta"
              rightIcon={<Icon />}
              onClick={() => {
                dataLayerPush({
                  event: `cta_click`,
                  interaction: {
                    click_text: cta.title,
                    link_url: cta.link,
                    cta_type: dataLayerType,
                    ...extraDataLayerProps
                  }
                });
              }}>
              {cta.title}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Card;
