/**
 * A helper function that pushes an event to the data layer.
 * @param {string} event The event name.
 * @param {object} interaction The interaction data, eg. click_text, link_url, etc.
 */
export default function dataLayerPush({ event, interaction = {} }) {
  // Do nothing if no event has been passed.
  if (typeof event !== `string`) {
    return;
  }

  const windowDefined =
    typeof window !== `undefined` && window.dataLayer !== null;

  const dataLayerObject = {
    event,
    interaction: {
      path: windowDefined === true ? window.location.pathname : `null`,
      ...interaction
    }
  };

  // Push to event on all environments except local.
  if (windowDefined === true) {
    window.dataLayer.push(dataLayerObject);
  }
}
